<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Chiusura attività</ion-title>
      </ion-toolbar>
    </ion-header>   
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>    
        </ion-toolbar>
      </ion-header>
      <form>
        <div id="container">
          <!--CLIENTE-->
          <div>
            <label>Cliente:</label>
            <ion-item>
              <ion-input readonly v-model="tasklog.account"> {{account.name}} </ion-input>
            </ion-item>
            <br>
          </div>
          <!--COMPANY RIFERIMENTO-->
          <label>Company di riferimento</label>
          <br>
          <ion-item id="cliente">
            <ion-select id="selcliente" placeholder="Seleziona il cliente" v-model="tasklog.company">
              <ion-select-option :value="c.name" v-for="c,i in companies" :key="'account-'+i">{{c.name}}</ion-select-option>
            </ion-select>
          </ion-item>
          <!--RIFERIMENTO OFFERTA-->
          <div>
            <br>
            <label>Riferimento offerta:</label>
            <ion-item>
              <ion-input readonly maxlength="255" v-model="tasklog.ref_off">{{$route.params.ref_off}}</ion-input>
            </ion-item>
          </div>
          <!--ORE LAVORATE-->
          <div>
            <br>
            <label>Ore lavorate:<span style="font-size:20px; color:red">*</span> </label>
            <ion-item>
              <ion-input maxlength="5" v-model="tasklog.hlav"></ion-input>
            </ion-item>
          </div>
          <!--ORE PAUSA-->
          <div>
            <br>
            <label>Ore di pausa:</label>
            <ion-item>
              <ion-input maxlength="5" v-model="tasklog.hpausa"></ion-input>
            </ion-item>
          </div>
          <!--TASK-->
          <div>
            <br>
            <label>Tipologia di task:<span style="font-size:20px; color:red">*</span></label>  
            <br>
            <ion-item>
              <ion-select placeholder="Seleziona il task.." v-model="tasklog.task_project" required>
                <ion-select-option :value="''+t.id" v-for="t,i in taskprojects" :key="'task-'+i">{{t.name}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <!--DESCRIZIONE ATTIVITA-->
          <div>
            <br>
            <label>Attività svolte:</label>
            <ion-item>
              <ion-textarea placeholder="Attività" clear-input maxlength="255" v-model="tasklog.description" required></ion-textarea>
            </ion-item>
          </div>
          <!--SEDE ATTIVITA-->
          <div>
            <br>
            <label>Sede svolgimento attività:<span style="font-size:20px; color:red">*</span></label>
            <br>
            <ion-item>
              <ion-select placeholder="Seleziona la sede.." v-model="tasklog.place">
                <ion-select-option :value="p" v-for="p,i in place" :key="'place-'+i" required>{{p}}</ion-select-option>
              </ion-select>
            </ion-item>
            <br>
          </div>
          <!--INDIRIZZO-->
          <div v-if="tasklog.place=='cliente' || tasklog.place=='fornitore'">
            <br>
            <label>Selezione indirizzo:</label>
            <br>
             <ion-item>
              <ion-select id="input" placeholder="Seleziona l'indirizzo"  v-model="selindirizzo">
                <ion-select-option :value="1">Indirizzo 1</ion-select-option>
                <ion-select-option :value="2">Indirizzo 2</ion-select-option>
                <ion-select-option :value="3">Indirizzo 3</ion-select-option>
                <ion-select-option :value="4">Indirizzo personalizzato</ion-select-option>
              </ion-select>
             </ion-item>
            <br>
            <div v-if="selindirizzo=='1'">
              <br>
              <label>Indirizzo:</label>
              <br>
              <ion-item>
                <ion-input id="citta" readonly v-model="account.city"></ion-input>
                <ion-input id="via" readonly v-model="account.address"></ion-input>
              </ion-item>
              <br>
            </div>
            <div v-if="selindirizzo=='2'"> 
              <br>
              <label>Indirizzo:</label>
              <br>
              <ion-item>
                <ion-input id="citta"  readonly v-model="account.city2"></ion-input>
                <ion-input id="via" readonly v-model="account.address2"></ion-input>
              </ion-item>
              <br>
            </div>
            <div v-if="selindirizzo=='3'">
              <br>
              <label>Indirizzo:</label>
              <br>
              <ion-item>
                <ion-input id="citta"  readonly v-model="account.city3"></ion-input>
                <ion-input id="via" readonly v-model="account.address3"></ion-input>
              </ion-item>
              <br>
            </div>
            <div v-if="selindirizzo=='4'">
              <br>
              <label>Indirizzo:</label>
              <br>
              <ion-item>
                <ion-input id="citta"  v-model="account.city4"></ion-input>
                <ion-input id="via" v-model="account.address4"></ion-input>
              </ion-item>
              <br>
            </div>
          </div>
          <!--TIPOLOGIA COSTO-->
          <div>
            <br>
            <label>Tipologia di costo:</label>  
            <br>
            <ion-item>
              <ion-select placeholder="Seleziona la tipologia.." v-model="tasklog.cost" required>
                <ion-select-option :value="''+c.id" v-for="c,i in costs" :key="'cost-'+i">{{c.cost}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <!--NUMERO VIAGGI-->
          <div>
            <br>
            <label>Numero di viaggi:</label>
            <br>
            <ion-item>
              <ion-input placheolder="0" v-model="tasklog.travel" @input="updatekmpedaggi" v-if="tasklog.place=='sede' || tasklog.place=='laboratorio'" :disabled="true"></ion-input>
              <ion-input type="number" min="0" v-model="tasklog.travel" clear-input placheolder="0"  @input="updatekmpedaggi" v-if="tasklog.place!='sede' && tasklog.place!='laboratorio'" ></ion-input>
            </ion-item>
          </div>
          <!--DISTANZA VIAGGIO-->
          <div>
            <br>
            <label>Km percorsi (solo andata):</label>
            <br>
            <ion-item>
              <ion-input type="number" min="0" clear-input placeholder='0' @input="updatekmpedaggi" v-if="tasklog.place=='sede' || tasklog.place=='laboratorio'" :disabled="true"></ion-input>
              <ion-input type="number" min="0" clear-input @input="updatekmpedaggi" v-if="tasklog.place!='sede' && tasklog.place!='laboratorio'"></ion-input>
            </ion-item>
          </div>
          <!--DISTANZA TOTALE VIAGGIO-->
          <div>
            <br>
            <label>km totali (km*viaggi)</label>
            
            <br>
            <ion-item>
              <ion-input id="totKm"  type="number" readonly v-model="tasklog.totalkm"></ion-input>
            </ion-item>
          </div>  
          <!--PEDAGGIO-->
          <div>
            <label>Costo pedaggio:</label>  
            <br>
              <ion-item>
                <ion-input  placeholder="0.00" type="number" min="0" clear-input maxlength="6"  @input="updatekmpedaggi" v-if="tasklog.place=='sede' || tasklog.place=='laboratorio'" :disabled="true"> </ion-input>
                 <ion-input  placeholder="0.00" type="number" min="0" clear-input maxlength="6"  @input="updatekmpedaggi" v-if="tasklog.place!='sede' && tasklog.place!='laboratorio'">€ &nbsp;</ion-input>
              </ion-item>
          </div>
          <!--DISTANZA TOTALE VIAGGIO-->
          <div>
            <br>
            <label>Pedaggio totale (pedaggio*viaggi)</label>
            <br>
            <ion-item>
              <ion-input type="number" readonly v-model="tasklog.totalpedaggio">€ &nbsp;</ion-input> 
            </ion-item>
          </div> 
          <!--PRANZO-->
          <div>
            <br>
            <label>Costo pranzo:</label><br>
            <br>    
            <ion-item>
              <ion-input placeholder="0.00" type="number" min="0" clear-input maxlength="6" v-model="tasklog.lunch_cost">€ &nbsp;</ion-input>
            </ion-item>
          </div>
          <!--ALTRI COSTI-->
          <div>
            <br>
            <label>Altri costi:</label><br>
            <br>      
            <ion-item>
              <ion-input placeholder="0.00" type="number" min="0" clear-input  maxlength="6" v-model="tasklog.other_cost">€ &nbsp;</ion-input>
            </ion-item>
          </div>
        <!--RIMBORSO SPESE-->
        <div>
          <br>
          <label>Rimborso spese:<span style="font-size:20px; color:red">*</span></label>
          <br>
            <ion-radio-group v-model="tasklog.rimb_spese">
          <ion-item>
              <label>Si &nbsp;</label>
              <ion-radio value="1"></ion-radio>
              <label> &nbsp;No &nbsp;</label>
              <ion-radio value="2"></ion-radio>
          </ion-item>
            </ion-radio-group>
        </div>
          <!--NOTE-->
          <div>
            <br>
            <label>Note:</label>
            <br>
            <ion-item>
              <ion-textarea placeholder="Note" clear-input maxlength="255" v-model="tasklog.notes"></ion-textarea>
            </ion-item>
            <br>
          </div>
        </div>
        <div>
          <ion-button @click="submit">Conferma chiusura</ion-button>
        </div>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButtons,
    IonButton,
    
    IonContent, 
    IonHeader, 
    IonInput,
    IonItem,
    IonMenuButton, 
    IonPage,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption, 
    IonTextarea,
    IonTitle, 
    IonToolbar, 
    toastController 
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButtons,
      IonButton,
      IonContent,
      IonHeader, 
      IonInput,
      IonItem,
      IonMenuButton, 
      IonPage,
      IonRadioGroup,
      IonRadio, 
      IonSelect,
      IonSelectOption,
      IonTextarea,
      IonTitle, 
      IonToolbar,  
    },
    data: function(){
      return {
        place:['sede','laboratorio','fornitore','cliente'],
        verificaPedaggio:true,
        verificaPranzo:true,
        accounts:[],
        account:{
          id:'',
          name:'',
          city:'',
          address:'',
          city2:'',
          address2:'',
          city3:'',
          address3:'',
          km:0,
          travel_cost:0,
        },
        costs:[],
        taskprojects:[],
        companies:[],
        tasklog:{
          company:'',
          consultant:'',
          date:'',
          hlav:'',
          hpausa:'',
          account:'',
          task_project:'',
          description:'',
          ref_off:'',
          place:'',
          city:'',
          address:'',
          hours:0,
          cost:'',
          travel:0,
          km:'',
          travel_cost:'',
          lunch_cost:'',
          other_cost:'',
          notes:'',
          rimb_spese:'2',
        },
        user:{
          name:'',
          email:'',
          password:''
        },
        totaleKm:0,
        totalePedaggi:0,
        kmpedaggiflag:0,
        selindirizzo:0,
        rimb:true,
      }
    },
    methods:{
      clienteSel(cliente){
        this.clienteSel=cliente;
        return this.clienteSel;
      }, 
      async submit(ev){
        ev.preventDefault();
        if(this.kmpedaggiflag==0){
          this.tasklog.km=this.account.km
          this.tasklog.travel_cost=this.account.travel_cost
        }
        if(this.selindirizzo==2){
          this.tasklog.city=this.account.city2;
          this.tasklog.address=this.account.address2;
        }else if(this.selindirizzo==3){
          this.tasklog.city=this.account.city3;
          this.tasklog.address=this.account.address3;
        }else if(this.selindirizzo==4){
          this.tasklog.city=this.account.city4;
          this.tasklog.address=this.account.address4;
        }else if(this.tasklog.place=='sede' || this.tasklog.place=='laboratorio'){
          this.tasklog.km=0;
          this.tasklog.travel_cost=0;
        }
        this.tasklog.rimb_spese==1;
        if(this.rimb==false){
          this.tasklog.rimb_spese==2;
        }
        if(this.tasklog.hlav!='' && this.tasklog.task_project!='' && this.tasklog.place!='' && this.tasklog.rimb_spese!=''){
          this.axios.post(this.apiUrl + 'save-task', {tasklog:this.tasklog}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              this.tasklog.company='';
              this.tasklog.consultant='';
              this.tasklog.date='';
              this.tasklog.hlav='';
              this.tasklog.hpausa='';
              this.tasklog.account='';
              this.tasklog.task_project='';
              this.tasklog.description='';
              this.tasklog.ref_off='';
              this.tasklog.place='';
              this.tasklog.address='';
              this.tasklog.city='';
              this.tasklog.hours=0;
              this.tasklog.cost='';
              this.tasklog.travel=0;
              this.tasklog.km=0;
              this.tasklog.travel_cost=0;
              this.tasklog.lunch_cost=0;
              this.tasklog.other_cost=0;
              this.tasklog.notes='';
              const toast = await toastController
              .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
              })
              this.$router.push('/folder/report-attivita');
              return toast.present();
            }
          })
        }else{
          const toast = await toastController
              .create({
                color: 'danger',
                message: 'Compilare tutti i campi obbligatori',
                duration: 2000
              })
              return toast.present();
        }
      },
      updatekmpedaggi(){
        setTimeout(() => {
          this.kmpedaggiflag=1;
          this.tasklog.km=this.account.km
          this.tasklog.travel_cost=this.account.travel_cost
          this.tasklog.totalkm=this.tasklog.km*this.tasklog.travel
          this.tasklog.totalpedaggio=this.tasklog.travel_cost*this.tasklog.travel
        },50)
      },
      getAddress(){ 
        this.tasklog.address=this.account.address
        this.tasklog.city=this.account.city
        this.selindirizzo=0;
      },
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
          .then(async (response) => {
            this.user=response.data.user;
            this.tasklog.consultant=this.user.id;
            this.tasklog.company=this.user.company;
          })
      },
      getAccountInfo(){
        this.axios.post(this.apiUrl + 'get-account-info', {id:this.$route.params.cliente}, this.headers)
        .then(async (response) => {
          this.account=response.data.account;
        })  
      },
      getTaskProjects(){
        this.axios.post(this.apiUrl + 'get-task-projects', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.taskprojects=response.data.taskprojects              
          }
        })
      },
      getCosts(){
        this.axios.post(this.apiUrl + 'get-costs', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.costs=response.data.costs        
          }
        })
      },
      getAccounts(){
        this.axios.post(this.apiUrl + 'get-accounts', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.accounts=response.data.accounts              
          }
        })
      },
      getTaskProjectsList(){
        this.axios.post(this.apiUrl + 'get-task-projects-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.taskprojects=response.data.taskprojects              
          }
        })
      },
      getCostsList(){
        this.axios.post(this.apiUrl + 'get-costs-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.costs=response.data.costs        
          }
        })
      },
      getAccountsList(){
        this.axios.post(this.apiUrl + 'get-accounts-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.accounts=response.data.accounts              
          }
          this.getAddress();
        })
      },
      getCompaniesList(){
        this.axios.post(this.apiUrl + 'get-companies-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.companies=response.data.companies            
          }
        })
      },
      ionViewWillEnter: function(){    
        this.getUserInfo();
        this.getAccountInfo();
        this.getCompaniesList();
        this.getTaskProjectsList();
        this.getCostsList();
        this.getAccountsList();
        this.selindirizzo=0;
      },
    }
  }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }
  #container strong {
    font-size: 20px;
    line-height: 26px;
  }
  #container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }
  #container a {
    display:inline-block;
    align-items:center;
    justify-content:center;
    text-decoration: none;
  }
  ion-label{
    margin-left:50%;
    border-bottom:1px solid blue;
  }
  div{
    margin-top:10px;
  }
  ion-button{
    width:50%;
    margin-left:25%;
  }
  ion-item{
    min-width:90%;
    color:blue;
  }
  #via{
    width:50%;
  }

    @media only screen and (max-width: 600px) {
    ion-radio{
      border:2px solid grey;
      border-radius:60px;
    }
  }
</style>